<template>
  <page-content>
    <page-title heading="เอกสารประกอบการสอน">
      <!-- <template #right>
        <b-button size="sm">
          <font-awesome-icon
            :spin="isRefreshing"
            size="sm"
            icon="sync"
            @click="refresh"
          />
        </b-button>
      </template> -->
    </page-title>

    <h4 class="my-3">วีดีโอประกอบการสอน</h4>

    <b-row v-if="isFetching">
      <b-col cols="12" class="mb-1">
        <b-skeleton-img no-aspect height="100px"></b-skeleton-img>
      </b-col>

      <b-col cols="12">
        <b-skeleton-img no-aspect height="100px"></b-skeleton-img>
      </b-col>
    </b-row>

    <lesson-video-list v-else></lesson-video-list>

    <h4 class="my-4">เอกสารประกอบการสอน</h4>

    <lesson-document
      ref="documentTable"
      :fields="configFields"
      :params="configTableParams"
    ></lesson-document>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import LessonVideoList from "../../components/lesson/VideoList";
import LessonDocument from "../../components/lesson/Document.vue";
import { Lesson } from "../../models";

export default {
  components: {
    PageContent,
    PageTitle,
    LessonVideoList,
    LessonDocument,
  },

  data() {
    return {
      isFetching: false,
      configTableParams: {},
      configFields: [
        { key: "title", label: "ชื่อ" },
        { key: "actions", label: "ดาวน์โหลด" },
      ],
    };
  },

  watch: {
    "$route.path": {
      immediate: true,
      handler: "onRouteChanged",
    },
  },

  methods: {
    async onRouteChanged() {
      await this.fetch();
    },

    async fetch() {
      this.isFetching = true;

      try {
        await Lesson.api().findAll();
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถโหลดข้อมูลบทเรียนได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetching = false;
      }
    },
  },
};
</script>
