<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header">
      <div class="card-header--title">
        <small>{{ title }}</small>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <div class="h-100 d-flex flex-column justify-content-between">
        <loading
          v-if="isFetching"
          :message="loadingMessage"
          style="height: 310px"
        />

        <div v-show="!isFetching" class="h-100">
          <b-table
            ref="configTable"
            :busy.sync="isFetching"
            :items="documentLessons"
            :fields="fields"
            hover
            responsive
            thead-class="thead-light"
            class="mb-0"
            v-bind="{ ...$attrs, ...$props }"
            v-on="{ ...$listeners }"
          >
            <template #head(actions)="row">
              <div class="text-center">
                <span>{{ row.label }}</span>
              </div>
            </template>

            <template #cell(actions)="{ item }">
              <div class="text-center">
                <b-button size="sm" @click="download(item)">
                  ดาวน์โหลด
                </b-button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/misc/Loading";
import { saveAs } from "file-saver";
import { MDocument } from "../../models";

export default {
  props: {
    title: String,
    fields: Array,
    params: Object,
  },

  components: {
    Loading,
  },

  data() {
    return {
      isFetching: false,
      isDownloading: false,
      loadingMessage: "กำลังดาวน์โหลดข้อมูล",
    };
  },

  computed: {
    documentLessons() {
      return MDocument.query().get();
    },
  },

  methods: {
    async fetchDocuments() {
      this.isFetching = true;

      try {
        await MDocument.api().findAll();
      } catch (error) {
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลเอกสารประกอบการสอนได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }
    },

    async download(doc) {
      this.isDownloading = true;

      try {
        saveAs(doc.url, doc.title);
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถดาวน์โหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isDownloading = false;
      }
    },
  },

  created() {
    this.fetchDocuments();
  },
};
</script>
